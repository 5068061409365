.topnav {
  list-style: none;

  background: none !important;
}
/* .topnav a {
  padding: 0 20px;
  margin: 0 !important;
} */

.topnav a {
  letter-spacing: 1px;
}
.topnav a:hover,
.ant-anchor-link-active > .ant-anchor-link-title {
  color: #ccb588 !important;
  text-decoration: none;
}
.topnav a:active {
  background: none !important;
  color: #ccb588 !important;
}
.ant-anchor-ink a:visited {
  color: #ccb588 !important;
}
.ant-anchor-ink {
  display: none;
}
.ant-anchor {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding-left: 0;
}
.ant-affix {
  background: #eee6e1;
  /* box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1); */
}
/* .menugallery {
  padding-right: 0px;
} */
.ant-anchor-link {
  padding: 7px 0 7px 6%;
}
.menursvp {
  padding-left: 0 !important;
}

@media only screen and (min-width: 500px) {
  .ant-anchor-link {
    padding: 7px 0 7px 9%;
  }
}
@media only screen and (min-width: 720px) {
  .ant-affix {
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0);
  }
  .ant-anchor-link {
    padding: 7px 0 7px 12% !important;
  }
  .menursvp {
    padding-left: 0 !important;
  }
  .menugallery {
    padding-right: 3% !important;
  }
}

.RSVP {
  padding: 70px 0 0 0;
}
.RSVPbtn {
  margin: 0 10px;
}
.popup-date h4 {
  font-size: 20px;
}
.RSVPbtn .ant-btn {
  color: white !important;
  background: #ccb588 !important;
  border: 1px solid #ccb588 !important;
  box-shadow: none;
  width: 100%;
  border-radius: 0px;
  height: 47px;
}
.RSVPbtn .ant-btn:hover {
  color: #ccb588 !important;
  background: none !important;
  border: 1px solid #ccb588 !important;
  border: 0;
  box-shadow: none;
  width: 100%;
}
.RSVP h3 {
  padding: 0 10%;
}
.ant-btn:focus {
  background: none;
}
.rsvp-title {
  border-bottom: 1px solid rgb(226, 226, 226);
  margin-bottom: 15px;
  padding-bottom: 15px;
}
.RSVPbtn .ant-btn p {
  padding-top: 10px;
}

.nameVerification input {
  border: 1px solid #484848;
}
.nameVerification input:hover {
  border: 1px solid #cacaca;
}
.nameVerification {
  margin: 10px;
}
.nameVerification input {
  height: 47px;
}
.ant-radio-wrapper {
  margin-right: 25px;
}
.num .ant-form-item-label {
  margin-right: 10px;
}
.num .ant-form-item-control {
  text-align: left;
}
.require {
  font-size: 12px;
}

.popup-date {
  margin: 0 0 25px 0;
  padding: 20px 0;
  background: rgb(247, 247, 247);
  border-radius: 3px;
}
.popup-date .date {
  font-weight: 600;
  margin-top: 8px;
}
.popup-date h2 {
  margin-bottom: 8px;
}
.popup-date p {
  margin-bottom: 0;
}
.popup-date .require {
  margin: 0;
}
.text-center {
  text-align: center;
}

.ant-radio-checked .ant-radio-inner,
.ant-radio-wrapper:hover .ant-radio,
.ant-radio:hover .ant-radio-inner,
.ant-radio-input:focus + .ant-radio-inner {
  border-color: #ccb588;
}
.ant-radio-inner::after {
  background: #ccb588 !important;
}
.ant-btn-primary {
  background: #ccb588;
  width: 100%;
  border: none;
  height: 48px;
}
.ant-btn-primary:active,
.ant-btn-primary.active,
.ant-btn-primary:hover,
.ant-btn-primary:hover,
.ant-btn-primary:focus {
  background: #a7946e;
}
.name-con {
  flex-wrap: wrap;
}
.menu {
  text-align: left;
}
@media only screen and (min-width: 578px) {
  .comments .ant-form-item-control-wrapper {
    width: 80%;
  }
  /* .email .ant-form-item-control-wrapper {
    width: 85%;
  } */
  .textbox .ant-form-item-control-wrapper {
    width: 95%;
  }
}

/* For demo */
/*  .ant-carousel .slick-slide {
   text-align: center;
   height: 300px;
   line-height: 160px;
   background: none;
   overflow: hidden;
 } */
.ant-carousel .slick-slide img,
.ImgCon img {
  width: 100% !important;
}
.alice-carousel__dots-item:hover,
.alice-carousel__dots-item.__active {
  background: #9a7327;
}
.alice-carousel__prev-btn,
.alice-carousel__next-btn {
  display: none;
}
/* .ant-carousel .slick-slide h3 {
   color: #fff;
 }
 .ant-carousel .slick-slide p {
  font-family: "Nanum Myeongjo", serif;
   background: none;
   position: absolute;
   letter-spacing: 2px;
   bottom: 30%;
   left: 70%;
   color: #fff;
   text-shadow: 1px 1px 5px #000000;
   height: 20px;
 } */
/* .c-con {
   position: relative;
 }
 .ant-carousel .slick-dots {
   bottom: 32%;
 }
 .slick-slider .slick-initialized {
   width: 100%;
 }
 .slick-arrow .slick-next {
   right: 0% !important;
   display: none !important;
 }  */
.alice-carousel__dots li {
  background: #ccb588;
}
@media screen and (max-width: 480px) {
  .ant-back-top {
    right: 38px;
  }
}
@media only screen and (min-width: 400px) {
  .ant-carousel .slick-dots {
    bottom: 15%;
  }
}

@media only screen and (min-width: 760px) {
  .ant-carousel .slick-dots {
    bottom: 4%;
  }
  .ant-carousel .slick-slide p {
    bottom: 35%;
  }
}

body {
  background: #eee6e1;
}
footer {
  margin: 0 0 40px 0;
}

h1 {
  font-size: 24px;
  letter-spacing: 7px;
  margin-bottom: 0;
}
h3 {
  text-transform: uppercase;
  letter-spacing: 6px;
  margin: 0 auto;
  font-family: "Bitter", serif;
  color: #3a3935;
}
footer .copyright {
  margin-bottom: 10px;
  letter-spacing: 1px;
}
footer {
  font-size: 12px;
}
.add {
  font-size: 12px;
  letter-spacing: 6px;
  padding: 15px 0;
}
.title {
  padding: 0 10% 20px 10%;
}
.title p {
  margin-top: 10px;
}
h4 {
  font-weight: 600;
  color: rgb(44, 44, 44);
}
.toptext {
  text-transform: uppercase;
  letter-spacing: 6px;
  margin: 20px 0 35px 0;
}
.toptext h1 {
  font-family: "Nanum Myeongjo", serif;
}
.des {
  padding: 0 5%;
  /* text-align: left; */
}
.App {
  font-family: sans-serif;
  text-align: center;
}

.flex {
  display: flex;
}
.flexWrap {
  flex-wrap: wrap;
}
.center {
  justify-content: center;
}

.topbanner img,
.imgCon img {
  width: 100%;
}
.imgCon {
  width: 180px;
}
.topbanner {
  width: 100%;
  margin: 0 auto;
}
.gallery,
.ourstory,
.details {
  padding: 55px 0;
}
/* .details-text {
  margin-top: 25px;
} */
#map {
  width: 100%;
  height: 400px;
  background-color: grey;
}

@media only screen and (min-width: 500px) {
  h1 {
    font-size: 26px;
    letter-spacing: 10px;
  }
  .add {
    font-size: 16px;
    letter-spacing: 10px;
  }
  .container {
    padding: 0 4%;
    margin: 0 10%;
  }
}
@media only screen and (min-width: 720px) {
  .container {
    margin: 0 5%;
  }
  h1 {
    font-size: 30px;
    letter-spacing: 14px;
  }
}
@media only screen and (min-width: 800px) {
  .container {
    margin: 0 10%;
  }
}
@media only screen and (min-width: 950px) {
  .container {
    margin: 0 15%;
  }
}

@media only screen and (min-width: 1090px) {
  .container {
    margin: 0 20%;
  }
}

@media only screen and (min-width: 1300px) {
  .container {
    margin: 0 22%;
  }
}
@media only screen and (min-width: 1500px) {
  .container {
    margin: 0 24%;
  }
}

.des {
  padding-top: 10px;
  line-height: 1.5;
}
.mapImg {
  height: 200px;
  overflow: hidden;
}
.details-text {
  padding-top: 40px;
}

.donation-btn {
  margin: 0 10px;
}
.donation-btn .ant-btn {
  border: 1px solid #ccb588 !important;
  color: white !important;
  background: #ccb588 !important;
  box-shadow: none;
  width: 100%;
  border-radius: 0px;
  height: 47px;
}

.donation-btn .ant-btn p {
  padding-top: 10px;
}
.donation-btn .ant-btn:hover {
  color: #b4a076 !important;
  border: 1px solid #ccb588 !important;
  background: none !important;
  border: 0;
  box-shadow: none;
  width: 100%;
}

