.mapImg {
  height: 200px;
  overflow: hidden;
}
.details-text {
  padding-top: 40px;
}

.donation-btn {
  margin: 0 10px;
}
.donation-btn .ant-btn {
  border: 1px solid #ccb588 !important;
  color: white !important;
  background: #ccb588 !important;
  box-shadow: none;
  width: 100%;
  border-radius: 0px;
  height: 47px;
}

.donation-btn .ant-btn p {
  padding-top: 10px;
}
.donation-btn .ant-btn:hover {
  color: #b4a076 !important;
  border: 1px solid #ccb588 !important;
  background: none !important;
  border: 0;
  box-shadow: none;
  width: 100%;
}
